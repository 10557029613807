import { VestingTemplate } from "./VestingTemplate";

export type EsopPlan = {
  scope: string;
  esopPlanId: string;
  planType: string;
  planHoldingPercentage: number;
  planName: string;
  planDescription: string;
  totalShares: number;
  issuedShares: number;
  esopPlanState: PlanState;
  optionsReserved: number;
  planStartDate?: string;
  poolSize: number;
  conversionNumber: number;
  conversionShares: number;
  isFractional: boolean;
  trustId: string;
  sharesManagedByTrust: number;
  availableState: PlanState[];
  isStateChangeAllowed: boolean;
  unitName: string;
  unitType: string;
  note?: string;
};
export interface FinancialReportReq {
  showFutureVestings: boolean;
  date: string;
  includeResignedEmployees: boolean;
  planIds: string[];
}

export interface AddPlanRes {
  id: string;
  planName: string;
  planType: string;
  planDescription: string;
  poolSize: number;
  planTerm: number;
  planStartDate?: string;
  note?: string;
  esopPlanState: PlanState;
  conversionNumber: number;
  conversionShares: number;
  vesting: VestingTemplate;
  isFractional: boolean;
  isManagedByTrust: boolean;
  trustId: string;
  trustName: string;
  sharesManagedByTrust: number;
  unitType: string;
  unitName: string;
  forfeitureType: string;
  forfeitureConditions: ForfeitureCondition[];
}

export interface ForfeitureCondition {
  tenure?: string;
  extendedMonths: number;
}

export interface AddPlanReq {
  id?: string;
  isFractional: boolean;
  isManagedByTrust: boolean;
  trustId?: string;
  trustName: string;
  sharesManagedByTrust: number;
  planTerm: number;
  planType: string;
  vestingTemplateId: string;
  vestingTemplateName: string | null;
  planName: string;
  planDescription: string;
  planStartDate?: string;
  poolSize: number;
  notes: string;
  conversionNumber: number;
  conversionShares: number;
  poolShares: number;
  unitType: string;
  unitName: string;
  forfeitureType: string;
  forfeitureConditions: ForfeitureCondition[];
}

export interface SH6ReportReq {
  planIds: string[];
  date: string;
}

export interface GenericAxiosResponse {
  response?: {
    data?: {
      resultCode: string;
      reason: string;
    };
  };
}

export interface EsopAccountingReq {
  attritionValue: number;
  hasSelectedIGAAP?: boolean;
  hasSelectedFairMarketValue: boolean;
  hasSelectedGradedMethod: boolean;
  changeValuationPerVesting: boolean;
  startDate: Date;
  endDate: Date;
  planType: string;
}

export enum PlanState {
  ACTIVE = "ACTIVE",
  FOR_APPROVAL = "FOR_APPROVAL",
  AMENDMENT = "AMENDMENT",
  REJECTED = "REJECTED",
  CONSTRUCTED = "CONSTRUCTED",
}

export enum ForfeitureType {
  NEVER = "NEVER",
  MONTHLY = "MONTHLY",
  CONDITIONAL = "CONDITIONAL",
}
