/* eslint-disable etc/no-commented-out-code */
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import {
  useGetEmailSettings,
  useSetEmailSettings,
} from "../../queries/siteSettings";
import { usePermissionStore } from "../../store/permissionStore";
import { EmailSetting } from "../../types/SiteSettings";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";

function EmailSettings() {
  const {
    data: emailSettingDetails,
    refetch,
    isError,
    isFetched,
  } = useGetEmailSettings();
  const { mutate: setEmailSettings, isLoading: isSettingsSaved } =
    useSetEmailSettings();
  const { permission } = usePermissionStore();
  const isUserEsopViewer = isEsopViewer();
  const initialValues: EmailSetting = {
    emailsToBeSent: emailSettingDetails?.emailsToBeSent || false,
    approverMailToAdmin: emailSettingDetails?.approverMailToAdmin || false,
    communicationEmailId:
      emailSettingDetails?.communicationEmailId == null
        ? "hissa@rulezero.com"
        : emailSettingDetails?.communicationEmailId || "hissa@rulezero.com",
    senderNameForEmail:
      emailSettingDetails?.senderNameForEmail == null
        ? "Hissa"
        : emailSettingDetails?.senderNameForEmail || "Hissa",
    isAttachmentEnabled: emailSettingDetails?.isAttachmentEnabled || false,
    reminderEmailsToBeSent:
      emailSettingDetails?.reminderEmailsToBeSent || false,
    reminderEmailsStartFrom: emailSettingDetails?.reminderEmailsStartFrom || 0,
    reminderEmailsInterval: emailSettingDetails?.reminderEmailsInterval || 0,
    employeeInactivityLogout:
      emailSettingDetails?.employeeInactivityLogout || false,
    sendEmailsToEmployees: emailSettingDetails?.sendEmailsToEmployees || false,
    sendGrantEmailsToEmployees:
      emailSettingDetails?.sendGrantEmailsToEmployees || false,
    sendVestingEmailsToEmployees:
      emailSettingDetails?.sendVestingEmailsToEmployees || false,
    sendBuyBackEmailsToEmployees:
      emailSettingDetails?.sendBuyBackEmailsToEmployees || false,
    sendGrantOfferedEmailsToEmployees:
      emailSettingDetails?.sendGrantOfferedEmailsToEmployees || false,
    sendGrantWithdrawEmailsToEmployees:
      emailSettingDetails?.sendGrantWithdrawEmailsToEmployees || false,
    sendGrantReminderEmailsToEmployees:
      emailSettingDetails?.sendGrantReminderEmailsToEmployees || false,
    sendVestingCompleteEmailsToEmployees:
      emailSettingDetails?.sendVestingCompleteEmailsToEmployees || false,
    sendBuyBackOfferedEmailsToEmployees:
      emailSettingDetails?.sendBuyBackOfferedEmailsToEmployees || false,
    sendBuyBackClosedEmailsToEmployees:
      emailSettingDetails?.sendBuyBackClosedEmailsToEmployees || false,
    sendBuyBackReminderEmailsToEmployees:
      emailSettingDetails?.sendBuyBackReminderEmailsToEmployees || false,
    sendEmailsToAdmin: emailSettingDetails?.sendEmailsToAdmin || false,
    sendGrantEmailsToAdmin:
      emailSettingDetails?.sendGrantEmailsToAdmin || false,
    sendPlanEmailsToAdmin: emailSettingDetails?.sendPlanEmailsToAdmin || false,
    sendTransactionEmailsToAdmin:
      emailSettingDetails?.sendTransactionEmailsToAdmin || false,
    sendReminderEmailsToAdmin:
      emailSettingDetails?.sendReminderEmailsToAdmin || false,
    // employeeSignatureForGrant:
    //   emailSettingDetails?.employeeSignatureForGrant || false,
    // sendMonthlyStatement: emailSettingDetails?.sendMonthlyStatement || false,
  };

  const [initialSettings, setInitialSettings] = useState<EmailSetting>();

  const initialSettingsSet = useRef(false);

  useEffect(() => {
    if (emailSettingDetails) {
      formik.setValues(emailSettingDetails);
      if (!initialSettingsSet.current) {
        setInitialSettings(_.cloneDeep(initialValues));
        initialSettingsSet.current = true;
      }
    }
  }, [emailSettingDetails]);

  const [handleChanges, setHandleChanges] = useState<boolean>();

  const validationSchema = Yup.object({
    emailsToBeSent: Yup.boolean(),
    communicationEmailId: Yup.string().when(["emailsToBeSent"], {
      is: (emailsToBeSent: boolean) => emailsToBeSent === true,
      then: Yup.string().email().required("Valid Email is required"),
    }),
    senderNameForEmail: Yup.string().when(["emailsToBeSent"], {
      is: (emailsToBeSent: boolean) => emailsToBeSent === true,
      then: Yup.string()
        .required("Email Sender Name is required")
        .min(1, "minimum one character required")
        .max(100),
    }),
    reminderEmailsToBeSent: Yup.boolean().required(),
    reminderEmailsStartFrom: Yup.number().when(["reminderEmailsToBeSent"], {
      is: (reminderEmailsToBeSent: boolean) => reminderEmailsToBeSent === true,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    reminderEmailsInterval: Yup.number().when(["reminderEmailsToBeSent"], {
      is: (reminderEmailsToBeSent: boolean) => reminderEmailsToBeSent === true,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    employeeInactivityLogout: Yup.boolean().required(),
    approverMailToAdmin: Yup.boolean(),
    isAttachmentEnabled: Yup.boolean(),
    sendGrantEmailsToEmployees: Yup.boolean().required(),
    sendVestingEmailsToEmployees: Yup.boolean().required(),
    sendBuyBackEmailsToEmployees: Yup.boolean().required(),
    sendGrantOfferedEmailsToEmployees: Yup.boolean().required(),
    sendGrantWithdrawEmailsToEmployees: Yup.boolean().required(),
    sendGrantReminderEmailsToEmployees: Yup.boolean().required(),
    sendVestingCompleteEmailsToEmployees: Yup.boolean().required(),
    sendBuyBackOfferedEmailsToEmployees: Yup.boolean().required(),
    sendBuyBackClosedEmailsToEmployees: Yup.boolean().required(),
    sendBuyBackReminderEmailsToEmployees: Yup.boolean().required(),
    sendEmailsToAdmin: Yup.boolean().required(),
    sendGrantEmailsToAdmin: Yup.boolean().required(),
    sendPlanEmailsToAdmin: Yup.boolean().required(),
    sendTransactionEmailsToAdmin: Yup.boolean().required(),
    sendReminderEmailsToAdmin: Yup.boolean().required(),
    // sendMonthlyStatement: Yup.boolean().required(),
  });

  function handleOnCancel(values: EmailSetting) {
    values = initialValues;
  }
  const handleSubmit = (values: EmailSetting) => {
    // fix later to avoid any
    const changedValues: any = {};
    Object.keys(values).forEach((key) => {
      const originalKey: keyof EmailSetting = key as keyof EmailSetting;
      if (values[originalKey] !== initialValues[originalKey]) {
        changedValues[originalKey] = values[originalKey];
      }
    });
    setEmailSettings(changedValues, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };
  const formik = useFormik<EmailSetting>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      handleSubmit(values);
    },
    onReset: (values) => {
      handleOnCancel(values);
    },
  });

  useEffect(() => {
    if (initialSettingsSet.current) {
      const hasChanges = _.isEqual(emailSettingDetails, formik.values);

      setHandleChanges(hasChanges);
    }
  }, [emailSettingDetails, initialSettings, formik.values]);

  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  const emailSettingsErrors =
    errors.communicationEmailId ||
    errors.senderNameForEmail ||
    errors.emailsToBeSent ||
    errors.reminderEmailsToBeSent ||
    errors.reminderEmailsStartFrom ||
    errors.reminderEmailsInterval ||
    errors.employeeInactivityLogout ||
    errors.approverMailToAdmin ||
    errors.isAttachmentEnabled;
  function doPartialTouch() {
    formik.setFieldTouched("communicationEmailId");
    formik.setFieldTouched("senderNameForEmail");
    formik.setFieldTouched("emailsToBeSent");
    formik.setFieldTouched("reminderEmailsToBeSent");
    formik.setFieldTouched("reminderEmailsStartFrom");
    formik.setFieldTouched("reminderEmailsInterval");
    formik.setFieldTouched("employeeInactivityLogout");
    formik.setFieldTouched("approverMailToAdmin");
    formik.setFieldTouched("isAttachmentEnabled");
  }

  return (
    <form key="emailSettings" onSubmit={formik.handleSubmit}>
      <Box className="md:w-[425px] lg:w-[850px] p-4 bg-white rounded-lg overflow-auto">
        <Box className="max-h-[550px] overflow-auto">
          <VStack className="justify-between p-4 gap-9">
            <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
              Email Settings
            </Box>
            <HStack className="gap-10 pb-6">
              <VStack>
                <HStack className="flex-1">
                  <p className={` form-label text-sm font-medium  mt-4 mr-2`}>
                    Email Sender Name Address
                  </p>

                  <Input
                    type="text"
                    placeholder="Email Address"
                    className="lg:w-48 md:w-32"
                    {...formik.getFieldProps("communicationEmailId")}
                    disabled={!formik.values.emailsToBeSent}
                  />
                </HStack>
                <HStack className="justify-end">
                  {touched?.communicationEmailId &&
                    errors?.communicationEmailId && (
                      <Error text={errors?.communicationEmailId} />
                    )}
                </HStack>
              </VStack>
              <VStack>
                <HStack>
                  <p className={` form-label text-sm font-medium mt-4 mr-2`}>
                    Email Sender Name
                  </p>
                  <Input
                    type="text"
                    placeholder="Email Sender Name"
                    className="lg:w-48 md:w-32"
                    {...formik.getFieldProps("senderNameForEmail")}
                    disabled={!formik.values.emailsToBeSent}
                  />
                </HStack>
                <HStack className="justify-end">
                  {touched?.senderNameForEmail &&
                    errors?.senderNameForEmail && (
                      <Error text={errors?.senderNameForEmail} />
                    )}
                </HStack>
              </VStack>
            </HStack>
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Reminder Emails
              </p>
              <Box className="flex justify-end w-1/3 pr-20 ml-2">
                <SwitchButton
                  onClick={() => {
                    formik.setFieldValue(
                      "reminderEmailsToBeSent",
                      !formik.values.reminderEmailsToBeSent
                    );
                  }}
                  className=""
                  value={formik.values.reminderEmailsToBeSent}
                  label=""
                />
              </Box>
            </HStack>
            {formik.values.reminderEmailsToBeSent && (
              <VStack className="gap-4">
                <HStack>
                  <p className="mt-4 ml-6 text-sm font-medium form-label">
                    Start reminder Emails after
                  </p>
                  <VStack>
                    <Input
                      type="text"
                      placeholder="No. of days"
                      className="m-1 w-36"
                      {...formik.getFieldProps("reminderEmailsStartFrom")}
                    />
                    {touched?.reminderEmailsStartFrom &&
                      errors?.reminderEmailsStartFrom && (
                        <Error text={errors?.reminderEmailsStartFrom} />
                      )}
                  </VStack>
                  <p className="mt-4 ml-1 text-sm font-medium form-label">
                    Days
                  </p>
                </HStack>
                <HStack>
                  <p className="mt-4 ml-6 text-sm font-medium form-label">
                    Set reminder emails interval to
                  </p>
                  <VStack>
                    <Input
                      type="text"
                      placeholder="No. of days"
                      className="m-1 w-36"
                      {...formik.getFieldProps("reminderEmailsInterval")}
                    />
                    {touched?.reminderEmailsInterval &&
                      errors?.reminderEmailsInterval && (
                        <Error text={errors?.reminderEmailsInterval} />
                      )}
                  </VStack>
                  <p className="mt-4 ml-1 text-sm font-medium form-label">
                    Days
                  </p>
                </HStack>
              </VStack>
            )}
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Allow admin to deactivate inactive Employee accounts
              </p>
              <Box className="flex justify-end w-1/3 pr-20 ml-2">
                <SwitchButton
                  onClick={() => {
                    formik.setFieldValue(
                      "employeeInactivityLogout",
                      !formik.values.employeeInactivityLogout
                    );
                  }}
                  className=""
                  value={formik.values.employeeInactivityLogout}
                  label=""
                />
              </Box>
            </HStack>
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Allow admin to receive all approver Emails
              </p>
              <Box className="flex justify-end w-1/3 pr-20 ml-2">
                <SwitchButton
                  onClick={() => {
                    formik.setFieldValue(
                      "approverMailToAdmin",
                      !formik.values.approverMailToAdmin
                    );
                  }}
                  className=""
                  value={formik.values.approverMailToAdmin}
                  label=""
                />
              </Box>
            </HStack>
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                Allow Employees to receive attachment in the offer letter
              </p>
              <Box className="flex justify-end w-1/3 pr-20 ml-2">
                <SwitchButton
                  onClick={() => {
                    formik.setFieldValue(
                      "isAttachmentEnabled",
                      !formik.values.isAttachmentEnabled
                    );
                  }}
                  className=""
                  value={formik.values.isAttachmentEnabled}
                  label=""
                />
              </Box>
            </HStack>
          </VStack>
          <VStack className="justify-between p-4">
            <HStack className="justify-between">
              <p className={` form-label text-sm font-medium w-2/3 `}>
                <b>Allow Hissa to send notification Emails</b>
              </p>
              <Box className="flex justify-end w-1/3 pr-20 ml-2">
                <SwitchButton
                  onClick={() => {
                    formik.setFieldValue(
                      "emailsToBeSent",
                      !formik.values.emailsToBeSent
                    );
                  }}
                  className=""
                  value={formik.values.emailsToBeSent}
                  label=""
                />
              </Box>
            </HStack>
            {formik.values.emailsToBeSent && (
              <VStack className="gap-5 pl-10 mt-9">
                <HStack className="items-center justify-between">
                  <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                  <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                    Allow Hissa to send notification Emails to Employees
                  </p>
                  <Box className="flex justify-end w-1/3 pr-20 ml-2">
                    <SwitchButton
                      onClick={() => {
                        formik.setFieldValue(
                          "sendEmailsToEmployees",
                          !formik.values.sendEmailsToEmployees
                        );
                      }}
                      className=""
                      value={formik.values.sendEmailsToEmployees}
                      label=""
                    />
                  </Box>
                </HStack>
                {formik.values.sendEmailsToEmployees && (
                  <VStack className="justify-between gap-5 pl-10">
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Grant related Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendGrantEmailsToEmployees",
                              !formik.values.sendGrantEmailsToEmployees
                            );
                          }}
                          className=""
                          value={formik.values.sendGrantEmailsToEmployees}
                          label=""
                        />
                      </Box>
                    </HStack>
                    {formik.values.sendGrantEmailsToEmployees && (
                      <VStack className="justify-between gap-5 pl-10">
                        <HStack className="items-center justify-between">
                          <Icon
                            icon={"fluent-mdl2:radio-bullet"}
                            className="h-"
                          ></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            Offered Emails
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendGrantOfferedEmailsToEmployees",
                                  !formik.values
                                    .sendGrantOfferedEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values.sendGrantOfferedEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                        <HStack className="items-center justify-between">
                          <Icon
                            icon={"fluent-mdl2:radio-bullet"}
                            className="h-"
                          ></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            withdrawn Emails
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendGrantWithdrawEmailsToEmployees",
                                  !formik.values
                                    .sendGrantWithdrawEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values.sendGrantWithdrawEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                        <HStack className="items-center justify-between">
                          <Icon
                            icon={"fluent-mdl2:radio-bullet"}
                            className="h-"
                          ></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            Reminder Emails
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendGrantReminderEmailsToEmployees",
                                  !formik.values
                                    .sendGrantReminderEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values.sendGrantReminderEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                      </VStack>
                    )}
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Vesting related Emails to Employees
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendVestingEmailsToEmployees",
                              !formik.values.sendVestingEmailsToEmployees
                            );
                          }}
                          className=""
                          value={formik.values.sendVestingEmailsToEmployees}
                          label=""
                        />
                      </Box>
                    </HStack>
                    {formik.values.sendVestingEmailsToEmployees && (
                      <VStack className="justify-between gap-5 pl-10">
                        <HStack className="items-center justify-between">
                          <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            Vesting Completion Emails
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendVestingCompleteEmailsToEmployees",
                                  !formik.values
                                    .sendVestingCompleteEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values
                                  .sendVestingCompleteEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                      </VStack>
                    )}
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Buyback related Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendBuyBackEmailsToEmployees",
                              !formik.values.sendBuyBackEmailsToEmployees
                            );
                          }}
                          className=""
                          value={formik.values.sendBuyBackEmailsToEmployees}
                          label=""
                        />
                      </Box>
                    </HStack>
                    {formik.values.sendBuyBackEmailsToEmployees && (
                      <VStack className="justify-between gap-5 pl-10">
                        <HStack className="items-center justify-between">
                          <Icon
                            icon={"fluent-mdl2:radio-bullet"}
                            className="h-"
                          ></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            Offered Emails
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendBuyBackOfferedEmailsToEmployees",
                                  !formik.values
                                    .sendBuyBackOfferedEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values
                                  .sendBuyBackOfferedEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                        <HStack className="items-center justify-between">
                          <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            Closed or Completion Emails to Employees
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendBuyBackClosedEmailsToEmployees",
                                  !formik.values
                                    .sendBuyBackClosedEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values.sendBuyBackClosedEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                        <HStack className="items-center justify-between">
                          <Icon
                            icon={"fluent-mdl2:radio-bullet"}
                            className="h-"
                          ></Icon>
                          <p
                            className={` form-label text-sm font-medium w-2/3 mb-0`}
                          >
                            Reminder Emails
                          </p>
                          <Box className="flex justify-end w-1/3 pr-20 ml-2">
                            <SwitchButton
                              onClick={() => {
                                formik.setFieldValue(
                                  "sendBuyBackReminderEmailsToEmployees",
                                  !formik.values
                                    .sendBuyBackReminderEmailsToEmployees
                                );
                              }}
                              className=""
                              value={
                                formik.values
                                  .sendBuyBackReminderEmailsToEmployees
                              }
                              label=""
                            />
                          </Box>
                        </HStack>
                      </VStack>
                    )}
                  </VStack>
                )}
                <HStack className="items-center justify-between">
                  <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                  <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                    Allow Hissa to Send notification emails to Admins
                  </p>
                  <Box className="flex justify-end w-1/3 pr-20">
                    <SwitchButton
                      onClick={() => {
                        formik.setFieldValue(
                          "sendEmailsToAdmin",
                          !formik.values.sendEmailsToAdmin
                        );
                      }}
                      className=""
                      value={formik.values.sendEmailsToAdmin}
                      label=""
                    />
                  </Box>
                </HStack>
                {formik.values.sendEmailsToAdmin && (
                  <VStack className="justify-between gap-5 pl-10">
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Grant related Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendGrantEmailsToAdmin",
                              !formik.values.sendGrantEmailsToAdmin
                            );
                          }}
                          className=""
                          value={formik.values.sendGrantEmailsToAdmin}
                          label=""
                        />
                      </Box>
                    </HStack>
                    <HStack className="items-center justify-between hidden">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Monthly Statement Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendMonthlyStatement",
                              !formik.values.sendMonthlyStatement
                            );
                          }}
                          value={formik.values.sendMonthlyStatement!}
                          label=""
                        />
                      </Box>
                    </HStack>
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Plan related Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendPlanEmailsToAdmin",
                              !formik.values.sendPlanEmailsToAdmin
                            );
                          }}
                          className=""
                          value={formik.values.sendPlanEmailsToAdmin}
                          label=""
                        />
                      </Box>
                    </HStack>
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Transaction related (Buyback/Exercise/Liquidity) Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendTransactionEmailsToAdmin",
                              !formik.values.sendTransactionEmailsToAdmin
                            );
                          }}
                          className=""
                          value={formik.values.sendTransactionEmailsToAdmin}
                          label=""
                        />
                      </Box>
                    </HStack>
                    <HStack className="items-center justify-between">
                      <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                      <p
                        className={` form-label text-sm font-medium w-2/3 mb-0`}
                      >
                        Reminder Emails
                      </p>
                      <Box className="flex justify-end w-1/3 pr-20 ml-2">
                        <SwitchButton
                          onClick={() => {
                            formik.setFieldValue(
                              "sendReminderEmailsToAdmin",
                              !formik.values.sendReminderEmailsToAdmin
                            );
                          }}
                          className=""
                          value={formik.values.sendReminderEmailsToAdmin}
                          label=""
                        />
                      </Box>
                    </HStack>
                  </VStack>
                )}
              </VStack>
            )}
          </VStack>
        </Box>

        {!isUserEsopViewer && (
          <HStack className="justify-between mt-12">
            <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              className={`${
                !handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
                  ? " cursor-not-allowed"
                  : ""
              } flex items-center self-end justify-center`}
              disabled={
                isError ||
                handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
              }
              type="submit"
              loading={isSettingsSaved}
            >
              Save Settings
            </ButtonPrimary>
          </HStack>
        )}
      </Box>
    </form>
  );
}

export default EmailSettings;
